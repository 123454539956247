//Using google forms

import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './ContactUsSection.css';

const ContactUsSection = () => {
  // State to store form input values
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  // Handler to update state on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handler to manage form submission to Google Form
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a form to submit data to Google Forms
    const form = document.createElement("form");
    form.method = "POST";
    form.action ="https://docs.google.com/forms/d/e/1FAIpQLSfHcbfHDdcyU58JZuCjH9ucjLOkIE92QCz8EfJDh76L-8mPog/formResponse"
      // "https://docs.google.com/forms/d/e/1FAIpQLSdLUKWmiRwPGb6f9qC1k-t1-M3ynSFHu_2_hvt-A5cBn_wINA/formResponse";
    form.target = "hidden_iframe"; // Use hidden iframe to avoid reloading

    // Create input fields for each form data entry
    const nameField = document.createElement("input");
    nameField.type = "hidden";
    nameField.name = "entry.158508062"; // Update with your form entry ID
    nameField.value = formData.name;
    form.appendChild(nameField);

    const emailField = document.createElement("input");
    emailField.type = "hidden";
    emailField.name = "entry.798270568"; // Update with your form entry ID
    emailField.value = formData.email;
    form.appendChild(emailField);

    const messageField = document.createElement("input");
    messageField.type = "hidden";
    messageField.name = "entry.182246012"; // Update with your form entry ID
    messageField.value = formData.message;
    form.appendChild(messageField);

    // Submit the form
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);

    // Reset form fields
    setFormData({
      name: '',
      email: '',
      message: '',
    });

    alert('Message sent successfully!');
  };

  return (
    <div id="contactus" className="contact-section">
      <Container className="my-5">
        <Row>
          <Col md={6} className="contact-form">
            <h2 className="contact-title">Get in Touch</h2>
            <hr className="contact-divider" />
            <p className="contact-description" style={{ color: 'white' }}>
              Please fill out the form below to send us an email and we will get back to you as soon as possible.
            </p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formMessage" className="mb-3">
                <Form.Control
                  as="textarea"
                  name="message"
                  rows={5}
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Button variant="outline-light" type="submit" className="send-message-button">
                Send Message
              </Button>
            </Form>
          </Col>
          <Col md={6} className="contact-info" style={{ marginTop: '2rem' }}>
            <h3>Contact Info</h3>
            <ul className="info-list">
              <li><i className="fas fa-phone"></i> Phone: <a href="tel:+1480-263-9999" style={{ color: 'white', textDecoration: 'none' }}>+1 480-263-9999</a></li>
              <li><i className="fas fa-envelope"></i> Email: <a href="mailto:info@sky-strike.com" style={{ color: 'white', textDecoration: 'none' }}> info@sky-strike.com</a></li>
            </ul>
          </Col>
        </Row>
      </Container>
      {/* Hidden iframe for Google Forms submission */}
      <iframe name="hidden_iframe" style={{ display: "none" }}></iframe>
    </div>
  );
};

export default ContactUsSection;


//Without using google forms
// import React, { useState } from 'react';
// import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// import './ContactUsSection.css';

// const ContactUsSection = () => {
//   // State to store form input values
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     message: '',
//   });

//   // Handler to update state on input change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   // Handler to manage form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
  
//     try {
//       const response = await fetch('http://localhost:5000/send-email', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(formData),
//       });
  
//       if (response.ok) {
//         alert('Message sent successfully!');
//       } else {
//         alert('Failed to send message, please try again.');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       alert('Failed to send message, please try again later.');
//     }
//   };

//   return (
//     <div id="contactus" className="contact-section">
//       <Container className="my-5">
//         <Row>
//           <Col md={6} className="contact-form">
//             <h2 className="contact-title">Get in Touch</h2>
//             <hr className="contact-divider" />
//             <p
//                 className="contact-description"
//                 style={{color: 'white'}}
//             >
//               Please fill out the form below to send us an email and we will get back to you as soon as possible.
//             </p>
//             <Form onSubmit={handleSubmit}>
//               <Form.Group controlId="formName" className="mb-3">
//                 <Form.Control
//                   type="text"
//                   name="name"
//                   placeholder="Name"
//                   value={formData.name}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//               <Form.Group controlId="formEmail" className="mb-3">
//                 <Form.Control
//                   type="email"
//                   name="email"
//                   placeholder="Email"
//                   value={formData.email}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//               <Form.Group controlId="formMessage" className="mb-3">
//                 <Form.Control
//                   as="textarea"
//                   name="message"
//                   rows={5}
//                   placeholder="Message"
//                   value={formData.message}
//                   onChange={handleInputChange}
//                 />
//               </Form.Group>
//               <Button variant="outline-light" type="submit" className="send-message-button">
//                 Send Message
//               </Button>
//             </Form>
//           </Col>
//           <br></br>
//           <br></br>
//           <Col md={6} className="contact-info" style={{ marginTop: '2rem' }}>
//             <h3>Contact Info</h3>
//             <ul className="info-list">
//               <li><i className="fas fa-phone"></i> Phone: <a href="tel:+1480-263-9999" style={{ color: 'white', textDecoration: 'none' }}>+1 480-263-9999</a></li>
//               <li><i className="fas fa-envelope"></i> Email: <a href="mailto:info@sky-strike.com" style={{ color: 'white', textDecoration: 'none' }}> info@sky-strike.com</a></li>
//             </ul>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default ContactUsSection;


