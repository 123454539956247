import { useMemo } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const HeroSectionWithParticles = () => {
  // Initialize particles engine
  const particlesInit = async (engine) => {
    // Use loadFull to initialize all the necessary modules
    await loadFull(engine);
  };

  // Particle configuration
  const config = useMemo(() => ({
    autoPlay: true,
    background: {
      color: {
        value: "#0d47a1",
      },
      opacity: 1,
    },
    fullScreen: {
      enable: false,
      zIndex: 0,
    },
    detectRetina: true,
    fpsLimit: 120,
    interactivity: {
      detectsOn: "window",
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        onHover: {
          enable: true,
          mode: "grab",
          parallax: {
            enable: true,
            force: 60,
            smooth: 10,
          },
        },
        resize: {
          delay: 0.5,
          enable: true,
        },
      },
      modes: {
        grab: {
          distance: 400,
          links: {
            opacity: 1,
          },
        },
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      bounce: {
        horizontal: {
          value: 1,
        },
        vertical: {
          value: 1,
        },
      },
      collisions: {
        enable: false,
      },
      color: {
        value: "#ffffff",
      },
      links: {
        enable: true,
        distance: 150,
        color: "#ffffff",
        opacity: 0.4,
        width: 1,
      },
      move: {
        enable: true,
        speed: 2,
        direction: "none",
        outModes: {
          default: "out",
        },
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 100,
      },
      opacity: {
        value: {
          min: 0.1,
          max: 0.5,
        },
        animation: {
          enable: true,
          speed: 3,
          startValue: "random",
          destroy: "none",
        },
      },
      shape: {
        type: "circle",
      },
      size: {
        value: {
          min: 1,
          max: 10,
        },
        animation: {
          enable: true,
          speed: 20,
          startValue: "random",
          destroy: "none",
        },
      },
    },
    pauseOnBlur: true,
    pauseOnOutsideViewport: true,
  }), []);

  return (
    <div className="hero-section" style={{ position: "relative", overflow: "hidden", height: "100vh" }}>
      <Particles
        id="tsparticles"
        init={particlesInit} // This prop will initialize the engine
        options={config} // Configuration for the particle animation
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
        }}
      />
      {/* <div className="hero-content" style={{ position: "relative", zIndex: 1 }}>
      <div className="content-container" style={{ backgroundColor: "#ffffff", padding: "2rem", borderRadius: "10px", textAlign: "center" }}>
        <h1>We Are Sky-Strike</h1>
        <p>Sky Strike is an advanced AI-driven solution designed to enhance public safety by detecting firearms in real-time and deploying immobilizing lassos to prevent shootings. With integrated emergency alert systems and adaptive threat assessment, it ensures rapid response and effective crowd management during critical incidents.
        </p>
        <button className="btn btn-primary">Learn More</button>
        </div>
      </div>
    </div> */}
     <div className="hero-content" style={{ position: "relative", zIndex: 1, display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <div className="content-container" style={{ backgroundColor: "#ffffff", padding: "1.5rem", borderRadius: "8px", textAlign: "center", maxWidth: "400px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
          <h1 style={{ color: "#0d47a1", fontSize: "2rem" }}>We Are Sky-Strike</h1>
          <p style={{ color: "#333", fontSize: "1rem" }}>Sky Strike is an advanced AI-driven solution designed to enhance public safety by detecting firearms in real-time and deploying immobilizing lassos to prevent shootings. With integrated emergency alert systems and adaptive threat assessment, it ensures rapid response and effective crowd management during critical incidents.</p>
          <a href="#features">
            <button className="btn btn-primary" style={{ marginTop: "1rem" }}>
              Learn More
            </button>
          </a>

        </div>
      </div>
    </div>
  );
};

export default HeroSectionWithParticles;
